import { translations } from '@prezly/theme-kit-intl';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useSonyVisibleCategories } from '@/hooks';

import { SectionHeading } from '../SectionHeading';

import { Topic } from './components';

import styles from './NewsTopics.module.scss';

export type UsageArea = 'home' | 'search-overlay';

interface Props {
    area?: UsageArea;
    className?: string;
}

export function NewsTopics({ className, area = 'home' }: Props) {
    const categories = useSonyVisibleCategories();

    return (
        <div
            className={classNames(className, styles.wrapper, {
                [styles.usedInSearchOverlay]: area === 'search-overlay',
                [styles.usedInHome]: area === 'home',
            })}
        >
            <SectionHeading
                variant={area === 'search-overlay' ? 'dark' : 'light'}
                size={area === 'search-overlay' ? 'small' : 'medium'}
                headingTag="h2"
                title={<FormattedMessage {...translations.categories.title} />}
            />
            <div
                className={classNames(styles.topics, {
                    [styles.five]: categories.length <= 5,
                    [styles.six]: categories.length === 6,
                })}
            >
                {categories.map((category, index) => (
                    <Topic area={area} key={category.id} category={category} index={index} />
                ))}
            </div>
        </div>
    );
}
