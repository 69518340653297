import { DOWNLOAD, useAnalytics } from '@prezly/analytics-nextjs';
import type { AttachmentNode } from '@prezly/story-content-format';
import { translations } from '@prezly/theme-kit-intl';
import { UploadcareFile } from '@prezly/uploadcare';
import { FormattedMessage } from 'react-intl';

import { IconDownload } from '@/icons';

import { formatBytes } from './utils';

import styles from './Attachment.module.scss';

interface Props {
    node: AttachmentNode;
}

export function Attachment({ node }: Props) {
    const { track } = useAnalytics();
    const { file, description } = node;
    const { downloadUrl } = UploadcareFile.createFromPrezlyStoragePayload(file);
    const displayedName = description || file.filename;
    const fileExtension = file.filename.split('.').pop();
    const fileType = fileExtension?.toUpperCase();

    function handleClick() {
        track(DOWNLOAD.ATTACHMENT, { id: file.uuid });
    }

    return (
        <div className={styles.container}>
            <div>
                <h4 className={styles.name}>{displayedName}</h4>
                <h5 className={styles.type}>
                    {fileType}
                    {fileType && ' - '}
                    {formatBytes(file.size)}
                </h5>
            </div>

            <a
                id={`attachment-${file.uuid}`}
                href={downloadUrl}
                onClick={handleClick}
                className={styles.link}
            >
                <FormattedMessage {...translations.actions.download} />
                <IconDownload width={12} height={12} className={styles.icon} />
            </a>
        </div>
    );
}
