import classNames from 'classnames';
import Link from 'next/link';

import { useThemeSettings } from '@/hooks';
import type { StoryWithImage } from 'types';

import { StoryCategoriesAndDate } from '../StoryCategoriesAndDate';
import StoryImage from '../StoryImage';

import styles from './StoryCard.module.scss';

type Props = {
    story: StoryWithImage;
};

function StoryCard({ story }: Props) {
    const { categories, title } = story;
    const { showDate } = useThemeSettings();
    const hasCategories = categories.length > 0;

    return (
        <div className={styles.container}>
            <Link href={`/${story.slug}`} locale={false} className={styles.imageWrapper}>
                <StoryImage story={story} size="big" className={styles.image} />
            </Link>
            <div className={styles.content}>
                <StoryCategoriesAndDate story={story} isStaticCategories />

                <h2
                    className={classNames(styles.title, {
                        [styles.noDateAndCategories]: !hasCategories && !showDate,
                    })}
                >
                    <Link href={`/${story.slug}`} locale={false} className={styles.titleLink}>
                        {title}
                    </Link>
                </h2>
            </div>
        </div>
    );
}

export default StoryCard;
