import { useMediaQuery } from '@react-hookz/web';

const BREAKPOINT_TABLET = 768;
const BREAKPOINT_DESKTOP = 992;

export function useDevice() {
    const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_TABLET - 1}px)`, true);
    const isTablet = useMediaQuery(`(max-width: ${BREAKPOINT_DESKTOP - 1}px)`, true);
    const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINT_DESKTOP}px)`, true);

    return {
        isMobile,
        isTablet,
        isDesktop,
    };
}
