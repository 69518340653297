// TODO: These are placeholders from open source, need to pull original icons from Sony
export { default as SonyCartIcon } from './cart.svg';
export { default as SonyChevronRightIcon } from './chevron-right.svg';
export { default as SonyChevronRightDoubleIcon } from './chevron-right-double.svg';
export { default as SonyCloseIcon } from './close.svg';
export { default as SonyExternalLinkIcon } from './external-link.svg';
export { default as SonyHeartIcon } from './heart.svg';
export { default as SonyMenuIcon } from './menu.svg';
export { default as SonySearchIcon } from './search.svg';
export { default as SonyUserIcon } from './user.svg';
