// This hook is taken from MUI, and unnecessary functionality has been removed from it.
// It is required for the `usePagination` hook to function correctly.
// Source: https://github.com/mui/material-ui/blob/641c8188cc8ca0bd2a22453a6923487de30ccb34/packages/mui-utils/src/useControlled.js

import { useCallback, useRef, useState } from 'react';

interface UseControlledProps<T = unknown> {
    /**
     * Holds the component value when it's controlled.
     */
    controlled: T;
    /**
     * The default value when uncontrolled.
     */
    default: T;
}

export function useControlled<T = unknown>({
    controlled,
    default: defaultProp,
}: UseControlledProps<T>): [T, (newValue: T | ((prevValue: T) => T)) => void] {
    // isControlled is ignored in the hook dependency lists as it should never change.
    const { current: isControlled } = useRef(controlled !== undefined);
    const [valueState, setValue] = useState(defaultProp);
    const value = isControlled ? controlled : valueState;

    const setValueIfUncontrolled = useCallback((newValue: any) => {
        if (!isControlled) {
            setValue(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [value, setValueIfUncontrolled];
}
