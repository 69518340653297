import { translations } from '@prezly/theme-kit-intl';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { IconArrowRight } from '@/icons';
import { SonyEvent, useSonyAnalytics } from '@/modules/SonyAnalytics';
import type { StoryWithImage } from 'types';

import { StoryCategoriesAndDate } from '../StoryCategoriesAndDate';
import StoryImage from '../StoryImage';

import styles from './HighlightedStoryCard.module.scss';

type Props = {
    story: StoryWithImage;
};

function HighlightedStoryCard({ story }: Props) {
    const { title, subtitle } = story;
    const { fireEvent } = useSonyAnalytics();

    function handleClick() {
        fireEvent(SonyEvent.CLICKED_BANNER, { bannerType: 'top-stories', bannerName: story.slug });
    }

    return (
        <div className={styles.container}>
            <StoryImage story={story} size="extra-big" className={styles.image} />

            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <StoryCategoriesAndDate
                        className={styles.categoriesAndDate}
                        story={story}
                        variant="highlighted"
                        showAllCategories
                    />

                    <h2 className={styles.title}>
                        <Link
                            href={`/${story.slug}`}
                            onClick={handleClick}
                            locale={false}
                            className={styles.titleLink}
                        >
                            <span className={styles.linkMask} />
                            {title}
                        </Link>
                    </h2>

                    {subtitle && (
                        <p className={styles.subtitle}>
                            <Link
                                href={`/${story.slug}`}
                                onClick={handleClick}
                                locale={false}
                                className={styles.subtitleLink}
                            >
                                {subtitle}
                            </Link>
                        </p>
                    )}

                    <Link href={`/${story.slug}`} className={styles.readMore}>
                        <FormattedMessage {...translations.actions.readMore} />
                        <IconArrowRight width={14} height={14} />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HighlightedStoryCard;
