export { default as IconArrowRight } from './arrow-right.svg';
export { default as IconBuilding } from './building.svg';
export { default as IconCalendar } from './calendar.svg';
export { default as IconCaret } from './caret.svg';
export { default as IconChevronLeft } from './chevron-left.svg';
export { default as IconChevronRight } from './chevron-right.svg';
export { default as IconClock } from './clock.svg';
export { default as IconClose } from './close.svg';
export { default as IconDownload } from './download.svg';
export { default as IconEmail } from './email.svg';
export { default as IconImage } from './image.svg';
export { default as IconGlobe } from './globe.svg';
export { default as IconLink } from './link.svg';
export { default as IconLoading } from './loading.svg';
export { default as IconMenu } from './menu.svg';
export { default as IconMobile } from './mobile.svg';
export { default as IconPhone } from './phone.svg';
export { default as IconSearch } from './search.svg';
export { default as IconTag } from './tag.svg';
export { default as IconVideo } from './video.svg';
export { default as LogoPrezly } from './prezly.svg';
