import type { NewsroomGallery } from '@prezly/sdk';
import { getResolvedPath, useNewsroom } from '@prezly/theme-kit-nextjs';
import { useQuery } from '@tanstack/react-query';

async function fetchGalleries(
    query: GalleriesQuery,
): Promise<{ galleries: NewsroomGallery[]; galleriesTotal: number }> {
    const result = await fetch(getResolvedPath('/api/fetch-galleries'), {
        method: 'POST',
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(query),
    });

    if (!result.ok) {
        const { message } = await result.json();
        throw new Error(message);
    }

    return result.json();
}

export interface GalleriesQuery {
    page?: number;
    pageSize?: number;
    type?: NewsroomGallery.Type;
    filterQuery?: Object;
}

interface Props {
    initialQuery: GalleriesQuery;
    query: GalleriesQuery;
    initialData: { galleries: NewsroomGallery[]; galleriesTotal: number };
}

export function usePaginatedGalleries({ initialQuery, query, initialData }: Props) {
    const newsroom = useNewsroom();
    const { data, isFetching, isPreviousData } = useQuery(
        [newsroom.uuid, 'galleries', query],
        async () => fetchGalleries(query),
        {
            // Only keep the `initialData` for initial query which happens in server-side
            // https://github.com/TanStack/query/issues/1859#issuecomment-898999630
            initialData: () => {
                if (query === initialQuery) {
                    return initialData;
                }

                return undefined;
            },
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    return {
        galleries: data ? data.galleries : [],
        galleriesTotal: data ? data.galleriesTotal : 0,
        isLoading: isFetching && isPreviousData,
    };
}
