import classNames from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';
import { FormattedNumber } from 'react-intl';

import { IconChevronLeft, IconChevronRight } from 'icons';

import type { PaginationType } from '../lib';

import styles from './PaginationItem.module.scss';

interface Props {
    page: number | null;
    paginationType: PaginationType;
    selected: boolean;
    disabled: boolean;
    onClick: ComponentPropsWithoutRef<'button'>['onClick'];
}

export function PaginationItem({ page, paginationType, disabled, selected, onClick }: Props) {
    if (paginationType === 'start-ellipsis' || paginationType === 'end-ellipsis') {
        return <span className={styles.item}>…</span>;
    }

    if (paginationType === 'page' && page) {
        return (
            <button
                type="button"
                disabled={disabled}
                className={classNames(styles.item, styles.button, {
                    [styles.selected]: selected,
                })}
                onClick={onClick}
            >
                <FormattedNumber value={page} />
            </button>
        );
    }

    return (
        <button
            type="button"
            disabled={disabled}
            className={classNames(styles.item, styles.button)}
            onClick={onClick}
        >
            {paginationType === 'next' && <IconChevronRight width={16} height={16} />}
            {paginationType === 'previous' && <IconChevronLeft width={16} height={16} />}
        </button>
    );
}
