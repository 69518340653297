import type { NewsroomGallery } from '@prezly/sdk';
import { getGalleryThumbnail } from '@prezly/theme-kit-core';
import { useGetLinkLocaleSlug } from '@prezly/theme-kit-nextjs';
import UploadcareImage from '@prezly/uploadcare-image';
import classNames from 'classnames';
import Link from 'next/link';

import { IconImage, IconVideo } from '@/icons';

import styles from './GalleryCard.module.scss';

interface Props {
    className?: string;
    gallery: NewsroomGallery;
}

function GalleryCard({ className, gallery }: Props) {
    const { name, type, uuid } = gallery;
    const getLinkLocaleSlug = useGetLinkLocaleSlug();

    const galleryThumbnail = getGalleryThumbnail(gallery);

    const GalleryTypeIcon = type === 'video' ? IconVideo : IconImage;

    return (
        <Link
            href={`/media/album/${uuid}`}
            locale={getLinkLocaleSlug()}
            className={classNames(styles.container, className)}
        >
            <div className={styles.thumbnailWrapper}>
                {galleryThumbnail && (
                    <UploadcareImage
                        className={styles.thumbnail}
                        lazy
                        layout="fill"
                        objectFit="cover"
                        imageDetails={galleryThumbnail}
                    />
                )}
                <span className={styles.iconWrapper}>
                    <GalleryTypeIcon width={16} height={16} className={styles.icon} />
                </span>
            </div>
            <span className={styles.title}>{name}</span>
        </Link>
    );
}

export default GalleryCard;
