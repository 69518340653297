import type { Category } from '@prezly/sdk';
import { getCategoryUrl, getLocalizedCategoryData, LocaleObject } from '@prezly/theme-kit-core';
import { translations } from '@prezly/theme-kit-intl';
import { useCurrentLocale, useGetLinkLocaleSlug } from '@prezly/theme-kit-nextjs';
import Image from '@prezly/uploadcare-image';
import classNames from 'classnames';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { CATEGORY_IMAGES } from '@/constants';
import { IconArrowRight } from '@/icons';

import type { UsageArea } from '../../NewsTopics';

import styles from './Topic.module.scss';

interface Props {
    category: Category;
    area: UsageArea;
    index: number;
}

export function Topic({ category, area, index }: Props) {
    const currentLocale = useCurrentLocale();
    const { name } = getLocalizedCategoryData(category, currentLocale);
    const localeCode = currentLocale.toUnderscoreCode();
    const getLinkLocaleSlug = useGetLinkLocaleSlug();
    const localeUrl = localeCode ? getLinkLocaleSlug(LocaleObject.fromAnyCode(localeCode)) : false;
    const image = CATEGORY_IMAGES[index];

    return (
        <Link
            href={getCategoryUrl(category, currentLocale)}
            locale={localeUrl}
            className={classNames(styles.topic, {
                [styles.usedInSearchOverlay]: area === 'search-overlay',
            })}
        >
            {image && (
                <Image
                    alt={name}
                    imageDetails={image}
                    layout="fill"
                    objectFit="cover"
                    className={styles.image}
                    sizes={{ mobile: 700, tablet: 900, default: 500 }}
                />
            )}
            <div className={styles.content}>
                <h3 className={styles.title}>{name}</h3>
                <span className={styles.subtitle}>
                    <FormattedMessage {...translations.search.viewMore} />{' '}
                    <IconArrowRight width={11} height={11} />
                </span>
            </div>
        </Link>
    );
}
