import type { IndexedStory } from '@prezly/theme-kit-core';
import Image from '@prezly/uploadcare-image';
import classNames from 'classnames';

import { FALLBACK_IMAGE } from '@/constants';
import { type CardSize, getCardImageSizes } from '@/utils';
import type { StoryWithImage } from 'types';

import { getStoryThumbnail } from './lib';

import styles from './StoryImage.module.scss';

type Props = {
    story: StoryWithImage | IndexedStory;
    size: CardSize;
    className?: string;
};

function StoryImage({ story, size, className }: Props) {
    const image = getStoryThumbnail(story);

    if (image) {
        return (
            <Image
                imageDetails={image}
                alt={story.title}
                layout="fill"
                objectFit="cover"
                containerClassName={classNames(styles.imageContainer, className)}
                className={styles.image}
                sizes={getCardImageSizes(size)}
            />
        );
    }

    return (
        <div
            className={classNames(styles.imageContainer, styles.fallbackImageContainer, className)}
        >
            <div className={styles.fallbackImageWrapper}>
                <Image
                    imageDetails={FALLBACK_IMAGE}
                    objectFit="cover"
                    layout="fill"
                    alt="No image"
                    className={styles.fallbackImage}
                />
            </div>
        </div>
    );
}

export default StoryImage;
