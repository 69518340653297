import { DOWNLOAD, useAnalytics, VIEW } from '@prezly/analytics-nextjs';
import { Elements } from '@prezly/content-renderer-react-js';
import type { GalleryNode } from '@prezly/story-content-format';

import { SonyEvent, useSonyAnalytics } from '@/modules/SonyAnalytics';

interface Props {
    node: GalleryNode;
}

export function Gallery({ node }: Props) {
    const { track } = useAnalytics();
    const { fireEvent } = useSonyAnalytics();

    return (
        <Elements.Gallery
            node={node}
            onImageDownload={(image) => {
                track(DOWNLOAD.GALLERY_IMAGE, { id: image.uuid });
                fireEvent(SonyEvent.ASSET_DOWNLOADED, { asset: image.filename });
            }}
            onPreviewOpen={(image) => {
                track(VIEW.GALLERY_IMAGE, { id: image.uuid });
            }}
        />
    );
}
