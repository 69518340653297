import type { IndexedStory } from '@prezly/theme-kit-core';
import classNames from 'classnames';

import { useThemeSettings } from '@/hooks';
import type { StoryWithImage } from 'types';

import CategoriesList from '../CategoriesList';
import { StoryPublicationDate } from '../StoryPublicationDate';

import styles from './StoryCategoriesAndDate.module.scss';

interface Props {
    className?: string;
    isStaticCategories?: boolean;
    showAllCategories?: boolean;
    story: StoryWithImage | IndexedStory;
    variant?: 'default' | 'highlighted';
}

function isIndexedStory(candidate: any): candidate is IndexedStory {
    // IndexedStory doesn't have `is_pinned` property
    if ('is_pinned' in candidate) {
        return false;
    }
    return true;
}

export function StoryCategoriesAndDate({
    className,
    isStaticCategories = false,
    showAllCategories = false,
    story,
    variant = 'default',
}: Props) {
    const { categories } = story;
    const { showDate } = useThemeSettings();
    const shouldShowDate = isIndexedStory(story)
        ? showDate
        : showDate && (variant === 'default' || !story.is_pinned);

    return (
        <div
            className={classNames(styles.wrapper, className, {
                [styles.highlighted]: variant === 'highlighted',
                [styles.default]: variant === 'default',
            })}
        >
            <CategoriesList
                showAllCategories={showAllCategories}
                isStatic={isStaticCategories}
                className={styles.categories}
                categories={categories}
            />
            {categories.length > 0 && shouldShowDate && <span>・</span>}
            {shouldShowDate && (
                <span>
                    <StoryPublicationDate story={story} />{' '}
                </span>
            )}
        </div>
    );
}
