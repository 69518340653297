import type { Category, Story } from '@prezly/sdk';
import type { LocaleObject } from '@prezly/theme-kit-core';
import { getResolvedPath, useCurrentLocale, useNewsroom } from '@prezly/theme-kit-nextjs';
import { useQuery } from '@tanstack/react-query';

import type { StoryWithImage } from 'types';

async function fetchStories<T extends Story = Story>(
    query: StoriesQuery,
): Promise<{ stories: T[]; storiesTotal: number }> {
    const result = await fetch(getResolvedPath('/api/fetch-stories'), {
        method: 'POST',
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...query,
            ...(query.locale && {
                localeCode: query.locale.toUnderscoreCode(),
            }),
        }),
    });

    if (!result.ok) {
        const { message } = await result.json();
        throw new Error(message);
    }

    return result.json();
}

export interface StoriesQuery {
    page?: number;
    pageSize?: number;
    withHighlightedStory?: boolean;
    category?: Category;
    locale?: LocaleObject;
    include?: (keyof Story.ExtraFields)[];
    pinning?: boolean;
    filterQuery?: Object;
}

interface Props<T> {
    initialQuery: StoriesQuery;
    query: StoriesQuery;
    initialData: { stories: T[]; storiesTotal: number };
    highlightedStory?: StoryWithImage;
}

function shouldExcludeHighlightedStory(
    category: Category | undefined,
    highlightedStory?: StoryWithImage | undefined,
) {
    // If there is no highlighted story at all, such as on the "News" page,
    // we can return early without any need for the exclusion process.
    if (!highlightedStory) {
        return false;
    }

    // When the user is viewing the list with "All" filters for category tabs enabled,
    // the highlighted story is definitely present, so we should exclude it.
    if (!category) {
        return true;
    }

    // At this stage, the user has selected a category from the category tabs filter.
    // We need to check if the selected category exists in the highlighted story.
    // If it does, then the end result will definitely include the highlighted story,
    // so we should exclude it.
    return Boolean(highlightedStory.categories.find(({ id }) => id === category.id));
}

function getPageSize(
    category: Category | undefined,
    highlightedStory: StoryWithImage | undefined,
    initialQuery: StoriesQuery,
): number {
    if (category && shouldExcludeHighlightedStory(category, highlightedStory)) {
        return (initialQuery.pageSize as number) + 1;
    }

    return initialQuery.pageSize as number;
}

/**
 * [⚠️ WARNING]
 * If you pass `highlightedStory` to this hook, it will only work as expected for the first page
 *
 */
export function usePaginatedStories<T extends Story = Story>({
    initialQuery,
    query,
    initialData,
    highlightedStory,
}: Props<T>) {
    const newsroom = useNewsroom();
    const currentLocale = useCurrentLocale();

    const { data, isFetching, isPreviousData } = useQuery(
        [newsroom.uuid, 'stories', currentLocale.toUnderscoreCode(), query],
        async () => {
            const { stories, storiesTotal } = await fetchStories<T>({
                ...query,
                pageSize: getPageSize(query.category, highlightedStory, initialQuery),
                locale: currentLocale,
            });

            if (shouldExcludeHighlightedStory(query.category, highlightedStory)) {
                return { stories: stories.slice(1), storiesTotal: storiesTotal - 1 };
            }

            return { stories, storiesTotal };
        },
        {
            // Only keep the `initialData` for initial query which happens in server-side
            // https://github.com/TanStack/query/issues/1859#issuecomment-898999630
            initialData: () => {
                if (query === initialQuery) {
                    if (highlightedStory) {
                        return {
                            storiesTotal: initialData.storiesTotal - 1,
                            stories: initialData.stories.slice(1),
                        };
                    }

                    return initialData;
                }
                return undefined;
            },
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            /**
             * Disable cache, as we want to always use the latest data when rendering this page in SSR mode.
             * @see CARE-5688
             */
            cacheTime: 0,
        },
    );

    return {
        stories: data ? data.stories : [],
        storiesTotal: data ? data.storiesTotal : 0,
        isLoading: isFetching && isPreviousData,
    };
}
