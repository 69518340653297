import { useCategoriesWithStoriesInCurrentLocale } from './useCategoriesWithStoriesInCurrentLocale';

export function useSonyVisibleCategories() {
    const categories = useCategoriesWithStoriesInCurrentLocale();
    const visibleCategories = categories.splice(0, 6);
    const lastCategory = visibleCategories[5];

    // Do not display the sixth category if it's not B2B.
    if (lastCategory && lastCategory.display_name !== 'B2B') {
        return visibleCategories.slice(0, 5);
    }

    return visibleCategories;
}
