import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from './SectionHeading.module.scss';

interface Props {
    className?: string;
    description?: ReactNode;
    headingTag?: 'h1' | 'h2' | 'h3';
    title: ReactNode;
    variant?: 'light' | 'dark';
    size?: 'medium' | 'small';
}

export function SectionHeading({
    className,
    description,
    headingTag = 'h1',
    title,
    size = 'medium',
    variant = 'dark',
}: Props) {
    const Heading = headingTag;
    return (
        <div
            className={classNames(styles.wrapper, className, {
                [styles.dark]: variant === 'dark',
                [styles.light]: variant === 'light',
                [styles.medium]: size === 'medium',
                [styles.small]: size === 'small',
            })}
        >
            <Heading className={styles.title}>{title}</Heading>
            {description && <p className={styles.description}>{description}</p>}
        </div>
    );
}
