/* eslint-disable react/jsx-props-no-spreading */
import { SonyEvent, useSonyAnalytics } from '@/modules/SonyAnalytics';

import { PaginationItem } from './components';
import { usePagination } from './lib';

import styles from './Pagination.module.scss';

interface Props {
    /**
     * Callback fired when the page is changed.
     *
     * @param {number} page The page selected.
     */
    onChange: (page: number) => void;

    defaultPage?: number;

    /**
     * Total count of pages
     *
     * @param {number} count The total pages count to render.
     */
    count: number;
}

export function Pagination({ count, defaultPage, onChange }: Props) {
    const { items } = usePagination({ count: Math.ceil(count), defaultPage });
    const { fireEvent } = useSonyAnalytics();

    // If there is only one page or less, there is no need to render the pagination.
    if (count <= 1) {
        return null;
    }

    return (
        <nav>
            <ul className={styles.pagination}>
                {items.map(({ page, type, selected, onClick, disabled }, index) => (
                    <li key={index}>
                        <PaginationItem
                            selected={selected}
                            paginationType={type}
                            page={page}
                            onClick={(event) => {
                                onChange(page as number);
                                onClick(event);
                                fireEvent(SonyEvent.PAGINATION_CLICKED, { page: String(page) });
                            }}
                            disabled={disabled}
                        />
                    </li>
                ))}
            </ul>
        </nav>
    );
}
