import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { CUSTOM_MESSAGES } from '@/intl';
import { SonyEvent, useSonyAnalytics } from '@/modules/SonyAnalytics';

import styles from './Tabs.module.scss';

interface Props<ItemType extends { id: string | number }> {
    defaultIndex?: number;
    items: ItemType[];
    onChange: (item: ItemType | undefined) => void;
    disabled?: boolean;
    getItemLabel: (item: ItemType) => string;
}

export function Tabs<ItemType extends { id: string | number }>({
    defaultIndex,
    items,
    onChange,
    disabled,
    getItemLabel,
}: Props<ItemType>) {
    const { fireEvent } = useSonyAnalytics();

    const getClassName = useCallback<({ selected }: { selected: boolean }) => string>(
        ({ selected }) =>
            classNames(styles.item, { [styles.selected]: selected, [styles.disabled]: disabled }),
        [disabled],
    );

    function handleChange(index: number) {
        if (index === 0) {
            onChange?.(undefined);
            fireEvent(SonyEvent.FILTERS_UPDATED, { filter: '' });
            return;
        }
        // - 1 is to take `All` option into account
        const selectedItem = items[index - 1];
        onChange?.(selectedItem);
        fireEvent(SonyEvent.FILTERS_UPDATED, { filter: getItemLabel(selectedItem) });
    }

    return (
        <Tab.Group defaultIndex={defaultIndex} onChange={handleChange}>
            <Tab.List className={styles.list}>
                <Tab disabled={disabled} className={getClassName}>
                    <FormattedMessage {...CUSTOM_MESSAGES.misc.all} />
                </Tab>
                {items.map((item) => (
                    <Tab disabled={disabled} key={item.id} className={getClassName}>
                        {getItemLabel(item)}
                    </Tab>
                ))}
            </Tab.List>
        </Tab.Group>
    );
}
