import type { Category } from '@prezly/sdk';
import { getLocalizedCategoryData } from '@prezly/theme-kit-core';
import { useCurrentLocale } from '@prezly/theme-kit-nextjs';

import { Tabs } from '../Tabs';

interface Props {
    categories: Category[];
    onChange: (category: Category | undefined) => void;
    disabled?: boolean;
}

export function CategoriesTabs({ categories, onChange, disabled }: Props) {
    const currentLocale = useCurrentLocale();

    return (
        <Tabs
            items={categories}
            onChange={onChange}
            disabled={disabled}
            getItemLabel={(category) => getLocalizedCategoryData(category, currentLocale).name}
        />
    );
}
